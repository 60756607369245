.button-confirm-cancel-modal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.form-box-container {
  width: 100%;
  max-width: 650px;
  background-color: "background.paper";
  box-shadow: 24px;
  padding: 30px;
  padding-top: 20px;
  border-radius: 20px;
  max-height: 90vh;
  overflow-y: auto;
}

.data-grid-pagination-custom-fields {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.data-grid-pagination-custom-fields__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.box-column-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.box-column-container-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: normal;
  word-break: break-word;
  text-align: left;
}

.buttons-container-action {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.container-custom-fields {
  padding: 0px;
  height: 94vh;
  width: 100%;
  background-color: transparent;
}

.snackbar-message {
  text-align: left;
}

.modalBox {
  width: 100%;
  max-width: 650px;
  box-shadow: 24px 24px 24px rgba(0, 0, 0, 0.1);
  padding: 16px;
  border-radius: 8px;
  max-height: 90vh;
  height: 90vh;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialogActionsBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  height: 100%;
  padding: 8px 16px;
}