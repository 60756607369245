@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,400;1,700&display=swap');

:root {
  --dark-text: #042623;
  --dark-green: #074641;
  --light-purple: #b9a7ff;
  --beige: #efe3db;
  --white: #fbfcf7;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Merriweather", Helvetica, "Roboto", sans-serif;
}


.app {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c9c9c9;
}

::-webkit-scrollbar-thumb:hover {
  background: #b0b0b0;
}

/* Custom Scrollbars */
.sidenav-scroll::-webkit-scrollbar,
.sm-scroll::-webkit-scrollbar,
.warning-scroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.sidenav-scroll::-webkit-scrollbar-track,
.sm-scroll::-webkit-scrollbar-track,
.warning-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.sidenav-scroll::-webkit-scrollbar-thumb,
.sm-scroll::-webkit-scrollbar-thumb,
.warning-scroll::-webkit-scrollbar-thumb {
  background: transparent;
}

.sm-scroll::-webkit-scrollbar-thumb,
.warning-scroll::-webkit-scrollbar-thumb {
  background: #c9c9c9;
}

.sm-scroll::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}

.warning-scroll::-webkit-scrollbar-track {
  background: #0288d166;
}

.warning-scroll::-webkit-scrollbar-thumb {
  background: #0288d1;
}

.warning-scroll::-webkit-scrollbar-thumb:hover {
  background: #0277bd;
}

/* Layout Styles */
.main-layout {
  flex: 1;
}

.drawer {
  width: 85px;
  transition: width 0.7s;
}

.drawer.opened {
  width: 240px;
}

.report-style-class {
  flex: 1;
  width: 100%;
  height: 100%;
}

.report-style-class iframe {
  border: 0 !important;
}

/* Text Limit */
.text-limit-1line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* Example Container */
.example-container {
  width: 100%;
  height: 100vh;
}

.box {
  background: white;
  border-radius: 30px;
  width: 150px;
  height: 150px;
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 75px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-icon {
  width: 80%;
  height: 80%;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

iframe {
  border: 0 !important;
}
