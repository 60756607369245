.drawer {
  max-height: 100vh;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-top: 3rem;
}

.collapsible-box {
  position: absolute;
  top: 30px;
  z-index: 999;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-left: 8px;  
  padding-right: 4px;
  padding-top: 2px;   
  padding-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.collapsible-box.collapsed {
  right: 15px;
}

.collapsible-box.expanded {
  right: -27px;
}

.content {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;
}

.drawer.rounded {
  border-radius: 15px;
}

.motion-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: opacity 0.45s;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.footer-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
}

.footer-support {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0;
  cursor: pointer;
}

.footer-support:hover {
  color: black;
}

.motion-div-big {
  width: 125px;
  transition: width 0.5s;
}

.motion-div-small {
  width: 62.5px;
  transition: width 0.5s;
}

.motion-div-small-new-theme {
  height: 75%;
  transition: width 0.5s;
}

.avatar {
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  width: 100%;
  max-width: 125px;
  min-width: 62.5px;
}

.motion-avatar {
  max-width: 100px;
  min-width: 50px;
  max-height: 100px;
  min-height: 50px;
  position: relative;
  transition: width 0.5s, height 0.5s;
}

.motion-avatar-big {
  width: 100px;
  height: 100px;
  padding-top: 10px;
}

.motion-avatar-small {
  width: 50px;
  height: 50px;
  padding-top: 10px;
}

.button-perfil-styles {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.3;
  font-size: 14px;
}

.button-perfil-styles .MuiButton-endIcon {
  margin-left: 0;
}

.motion-hidden {
  opacity: 0;
  transition: opacity 0.45s;
}

.motion-show {
  opacity: 1;
  transition: opacity 0.45s;
}

.motion-name-div {
  transition: transform 0.45s;
}

.motion-name-big {
  transform: scale(1.25);
}

.motion-name-small {
  transform: scale(0.85);
}
