.report-style-class {
    flex: 1;
    width: 100%;
    height: 100%;
    position: relative;
}

.report-style-class iframe {
    border: none !important;
}

.fullscreen {
    flex: 1;
    width: 100%;
    height: 100%;
}
  