body, h1, h2 {
  font-family: 'Outfit', 'Roboto', Helvetica, sans-serif !important;
}

.left-image {
  width: 50%;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;
}

.left-image .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.left-image .logo-login-icon {
  position: relative;
  max-width: 17%;
  margin-bottom: 20px;
  margin-right: 30px;
}

.login-form {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px;
  box-sizing: border-box;
}

.login-form h1 {
  font-size: 54pt; 
  font-weight: bold;  
  margin-bottom: 48px;
  color: var(--dark-green); 
}

.login-form h2 {
  font-size: 34pt; 
  font-weight: bold;  
  margin-bottom: 12px;
  color: var(--dark-green); 
}

.login-form .form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input-container {
  width: 100%; 
  margin-bottom: 32px; 
}

.input-container:last-of-type {
  margin-bottom: 36px;
}

.login-form .icon-container {
  margin-bottom: 16px; 
}

.login-form .hand-icon {
  max-width: 180px;
  margin-right: 8px;
}

.login-form .MuiInputBase-root {
  font-size: 13pt;  
  font-weight: 500;  
  color: var(--dark-text);  
}

.login-form .MuiButton-root {
  font-size: 8pt;  
  font-weight: 600; 
  text-transform: none;
  border-radius: 24px;
  padding: 10px 10px 10px 10px;
  background-color: var(--light-purple); 
  color: var(--dark-text); 
  margin-bottom: 16px;
  border: solid 2px var(--light-purple);
}

.login-form .MuiButton-root:hover {
  background-color: var(--white);  
}

.forgot-password {
  font-size: 9pt; 
  font-weight: 550; 
  color: var(--dark-text);
  text-decoration: none;
  padding: 12px;
}

.forgot-password:hover {
  text-decoration: underline;
}

.error {
  font-family: 'Outfit', 'Roboto', Helvetica, sans-serif;
  color: crimson;
  font-size: 12px;
  margin-top: 4px;
}